import React, { useRef, useState, useMemo } from "react";
import Button from "../../../components/Button";
import { useUsers } from "../../../hooks/useData";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { writeData } from "../../../services/FirebaseService";

const NewNotification = () => {
  const titleRef = useRef(null);
  const contentRef = useRef(null);
  const targetRef = useRef(null);
  const imageRef = useRef(null);

  const [users] = useUsers();

  const [errorText, setErrorText] = useState(null);
  const [successText, setSuccessText] = useState(null);

  const filteredUsers = useMemo(
    () => users && users.filter((u) => !!u.messaging_token),
    [users]
  );

  const sendNotification = async () => {
    const title = titleRef.current?.value;
    const content = contentRef.current?.value;
    const target = targetRef.current?.value;
    const image = imageRef.current?.value;
    setSuccessText(null);
    if (!title) {
      setErrorText("Title is required.");
      return;
    }
    if (!content) {
      setErrorText("Content is required.");
      return;
    }
    if (!target) {
      setErrorText("Target is required.");
      return;
    }
    if (image && !image.startsWith("http")) {
      setErrorText("Image must start with http or https.");
      return;
    }

    setErrorText(null);
    const notification = {
      title,
      body: content,
      image,
    };
    if (target === "GLOBAL") {
      notification.target_type = "TOPIC_GLOBAL_PURPOSE";
    } else {
      notification.target_type = "TOKEN";
      notification.target_value = target;
    }

    const result = {
      data: notification,
      type: "NOTIFICATION_ALERT",
    };

    await writeData("Actions", result);
    setSuccessText("Notification sent succesfully.");
  };

  const reset = () => {
    titleRef.current.value = "";
    contentRef.current.value = "";
    targetRef.current.value = "GLOBAL";
  };

  console.log(contentRef);

  return filteredUsers ? (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header">
          <strong>New Notification</strong> WSF
        </div>
        <div className="card-body card-block">
          <div className="row form-group">
            <div className="col col-md-3">
              <label htmlFor="select" className=" form-control-label">
                Target
              </label>
            </div>
            <div className="col-12 col-md-9">
              <select name="select" className="form-control" ref={targetRef}>
                <option selected value="GLOBAL">
                  Global
                </option>
                {filteredUsers.map((user) => (
                  <option value={user.messaging_token}>
                    {user.first_name} {user.last_name} - {user.email_address}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row form-group">
            <div className="col col-md-3">
              <label for="text-input" className=" form-control-label">
                Notification Title
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                type="text"
                ref={titleRef}
                placeholder="Text"
                className="form-control"
              />
              <small className="form-text text-muted">
                No more than 36 Characters
              </small>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label htmlFor="text-input" className=" form-control-label">
                Notification Content
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                type="text"
                ref={contentRef}
                placeholder="Text"
                className="form-control"
              />
              <small className="form-text text-muted">
                No more than 100 Characters
              </small>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label htmlFor="text-input" className=" form-control-label">
                Notification Image
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                type="text"
                ref={imageRef}
                placeholder="https://imageurl.com/img.jpg"
                className="form-control"
              />
              <small className="form-text text-muted">
                Image url starts with https:// and ends with .png, .jpg or .jpeg
              </small>
            </div>
          </div>

          <div id="loading_stats"></div>
        </div>
        {errorText && (
          <div className="alert alert-danger mx-8" role="alert">
            {errorText}
          </div>
        )}
        {successText && (
          <div className="alert alert-success mx-8" role="success">
            {successText}
          </div>
        )}
        <div className="card-footer">
          <Button
            text="Send notification"
            type="success"
            callback={sendNotification}
          />
          <br />
          <Button text="Reset" type="danger" callback={reset} />
        </div>
      </div>
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default NewNotification;
