import React from "react";
import { useNavigate } from "react-router-dom";
import logoImage from "../../assets/images/logo.png";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Button from "../../components/Button";

const LoginPage = () => {
  const emailRef = React.useRef(null);
  const passRef = React.useRef(null);

  const navigate = useNavigate();

  const connectUser = async () => {
    try {
      const auth = getAuth();
      const user = await signInWithEmailAndPassword(
        auth,
        emailRef.current.value,
        passRef.current.value
      );
      if (!user) {
        throw new Error("User not found");
      }
      navigate("/Home");
    } catch (error) {
      console.error(error);
      alert("Cannot login to your account.");
      return;
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content--bge5">
        <div className="container">
          <div className="login-wrap">
            <div className="login-content">
              <div className="login-logo">
                <img src={logoImage} alt="CoolAdmin" className="m-auto" />
              </div>
              <div className="login-form">
                <div className="form-group">
                  <label>Adresse mail</label>
                  <input
                    className="au-input au-input--full"
                    type="email"
                    ref={emailRef}
                    placeholder="Adresse mail"
                  />
                </div>
                <div className="form-group">
                  <label>Mot de passe</label>
                  <input
                    className="au-input au-input--full"
                    type="password"
                    ref={passRef}
                    placeholder="Mot de passe"
                  />
                </div>
                <Button text="Submit" type="primary" callback={connectUser} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
