import React, { useState, useRef } from "react";
import Button from "../../../components/Button";
import { useData } from "../../../hooks/useData";
import {
  uploadStorageFile,
  updateData,
} from "../../../services/FirebaseService";
import LoadingSpinner from "../../../components/LoadingSpinner";

const ApplicationDesign = () => {
  const {
    data: { personalData },
  } = useData();

  const [errorText, setErrorText] = useState(null);
  const [successText, setSuccessText] = useState(null);
  const [uploadingProgress, setUploadingProgress] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const imageBgRef = useRef(null);

  const update = async () => {
    setSuccessText(null);
    setErrorText(null);
    try {
      setLoading(true);
      const imageUrl = await getImageLink();
      await updateData("Design/bg-color", imageUrl);
      setSuccessText("Image uploaded succesfully.");
      reset();
    } catch (error) {
      console.error(error);
      setErrorText(error.message);
    }
    setLoading(false);
    setUploadingProgress(null);
  };

  const getImageLink = () => {
    if (!imageBgRef.current.files || !imageBgRef.current.files.length) {
      throw new Error("Image File not found.");
    }
    const file = imageBgRef.current.files[0];
    if (
      !["image/png", "image/jpg", "image/jpeg", "image/gif"].includes(file.type)
    ) {
      throw new Error("File's format must be png, jpg, jpeg or gif.");
    }

    return new Promise((resolve, reject) => {
      uploadStorageFile(
        "Design",
        `appbg.${file.type}`,
        file,
        (percentage) => setUploadingProgress(percentage),
        (error) => reject(error),
        (url) => resolve(url)
      );
    });
  };

  const reset = () => {
    if (!imageBgRef.current) {
      return;
    }
    imageBgRef.current.value = "";
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header">
          <strong>Application Design</strong> WSF
        </div>
        <div className="card-body card-block">
          <div className="row form-group">
            <div className="col col-md-3">
              <label className=" form-control-label">Publisher</label>
            </div>
            <div className="col-12 col-md-9">
              <p className="form-control-static">
                {personalData.first_name} {personalData.last_name}
              </p>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label for="text-input" className=" form-control-label">
                Background image
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                type="file"
                ref={imageBgRef}
                id="name"
                name="text-input"
                placeholder="Text"
                className="form-control"
              />
              <small className="form-text text-muted">
                Allowed extension are .png, .jpg, .jpeg, .gif
              </small>
            </div>
          </div>
        </div>
        {uploadingProgress != null && (
          <div className="alert alert-danger mx-8" role="info">
            Uploading file: {uploadingProgress}%<br />
            Please keep the current page open.
          </div>
        )}
        {errorText && (
          <div className="alert alert-danger mx-8" role="alert">
            {errorText}
          </div>
        )}
        {successText && (
          <div className="alert alert-success mx-8" role="success">
            {successText}
          </div>
        )}
        <div className="card-footer">
          <Button text="Update" type="primary" callback={update} />
          <br />
          <Button text="Reset" type="danger" callback={reset} />
        </div>
      </div>
    </div>
  );
};

export default ApplicationDesign;
