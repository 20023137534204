import React, { useEffect, useState, useMemo } from "react";
import Button from "../../../components/Button";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useUsers } from "../../../hooks/useData";
import { FormatDate } from "../../../R.js";
import { updateData, removeData } from "../../../services/FirebaseService";

const UserTr = ({
  user_name,
  user_email,
  creating_time,
  expiring_time,
  role,
  is_active,
  activeFunction,
  renewFunction,
  deleteUser
}) => {
  if (!user_email) {
    console.log(expiring_time);
  }
  const inscription = new Date(creating_time);
  const expiration = new Date(expiring_time);
  const now = new Date().getTime();
  const lastDay = now > expiring_time ? expiring_time : now;
  return (
    <tr className={is_active ? "" : "bg-red-100"}>
      <td>
        <div className="table-data__info">
          <h6>{user_name}</h6>
          <span>
            <a href={`mailto:${user_email}`}>{user_email}</a>
          </span>
        </div>
      </td>
      <td>
        <div className="table-data__info">
          <h6>{inscription.toLocaleDateString()}</h6>
        </div>
      </td>
      <td>
        <div className="table-data__info">
          <h6>{expiration.toLocaleDateString()}</h6>
        </div>
      </td>
      <td>
        <span class={`badge rounded-pill bg-info w-full mb-2 py-2 text-white`}>
          {expiring_time > now
            ? Math.round((expiring_time - now) / (1000 * 60 * 60 * 24))
            : 0}{" "}
          days
        </span>
      </td>
      <td>
        <span
          class={`badge rounded-pill bg-warning w-full mb-2 py-2 text-white`}
        >
          {Math.round((lastDay - creating_time) / (1000 * 60 * 60 * 24))} days
        </span>
      </td>
      <td>
        <span className="role member">{role}</span>
      </td>
      <td>
        <Button
          text="Renew 1 Month"
          type="primary"
          callback={() => renewFunction(false)}
          isSmall={true}
        />
        {is_active ? (
          <Button
            text="Desactivate Account"
            type="danger"
            callback={() => activeFunction(false)}
            isSmall={true}
          />
        ) : (
          <Button
            text="Activate Account"
            type="success"
            callback={() => activeFunction(true)}
            isSmall={true}
          />
        )}
       <Button
          text="Permanently delete user"
          type="danger"
          callback={() => deleteUser()}
          isSmall={true}
        />
      </td>
    </tr>
  );
};

const Users = () => {
  const [users, setUsers] = useUsers();

  const filteredUsers = useMemo(
    () =>
      users &&
      users.length &&
      users.sort((u1, u2) => u2.creating_time - u1.creating_time),
    [users]
  );

  const setActivated = async (key, setActive) => {
    await updateData(`Users/${key}/account_active`, setActive);
    setUsers((users) =>
      users.map((u) =>
        u.key === key ? { ...u, account_active: setActive } : u
      )
    );
  };

  const renewFunction = async (key) => {
    const user = users.find((u) => u.key === key);
    const expiring_time = user.expiring_time + 2628000000;
    await updateData(`Users/${key}/expiring_time`, expiring_time);
    setUsers((users) =>
      users.map((u) => (u.key === key ? { ...u, expiring_time } : u))
    );
  };

  const deleteUser = async (key) => {
    const user = users.find((u) => u.key === key);
    if(!user){
      alert("User is not found!");
      return;
    }
    await removeData(`Users/${key}`);
    setUsers((users) =>
      users.filter((u) => (u.key !== key))
    );
  }

  return filteredUsers ? (
    <div className="col-lg-12">
      <div className="user-data m-b-30">
        <h3 className="title-3 m-b-30">
          <i className="zmdi zmdi-account-calendar"></i>users data
        </h3>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <td>name</td>
                <td>Inscription Date</td>
                <td>Expiration Date</td>
                <td>Days Left</td>
                <td>Duration</td>
                <td>Role</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map(
                ({
                  key,
                  first_name,
                  last_name,
                  email_address,
                  creating_time,
                  expiring_time,
                  user_role,
                  account_active,
                }) => (
                  <UserTr
                    user_name={`${first_name} ${last_name}`}
                    creating_time={+creating_time}
                    expiring_time={+expiring_time}
                    role={user_role}
                    user_email={email_address}
                    is_active={account_active}
                    activeFunction={(isActive) => setActivated(key, isActive)}
                    renewFunction={() => renewFunction(key)}
                    deleteUser={() => deleteUser(key)}
                  />
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default Users;
