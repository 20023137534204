import React, { useState, useRef } from "react";
import Button from "../../../components/Button";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useData } from "../../../hooks/useData";
import { writeData } from "../../../services/FirebaseService";

const NewSignal = () => {
  const {
    data: { personalData },
    setters: { setSignalsData },
  } = useData();

  const [errorText, setErrorText] = useState(null);
  const [successText, setSuccessText] = useState(null);

  const idRef = useRef(null);
  const categoryRef = useRef(null);
  const adviceRef = useRef(null);
  const levelRef = useRef(null);
  const originRef = useRef(null);
  const tp1Ref = useRef(null);
  const tp2Ref = useRef(null);
  const slRef = useRef(null);
  const priceRef = useRef(null);

  const addSignal = async () => {
    const id = idRef.current.value;
    const category = categoryRef.current.value;
    const advice = adviceRef.current.value;
    const level = levelRef.current.value;
    const origin = originRef.current.value;
    const tp1 = tp1Ref.current.value;
    const tp2 = tp2Ref.current.value;
    const sl = slRef.current.value;
    const price = priceRef.current.value;

    if (!id) {
      setErrorText("Signal id is required.");
      return;
    }
    if (!category) {
      setErrorText("Signal category is required.");
      return;
    }
    if (!advice) {
      setErrorText("Signal advice is required.");
      return;
    }
    if (!level) {
      setErrorText("Signal level is required.");
      return;
    }
    if (!origin) {
      setErrorText("Signal origin is required.");
      return;
    }
    if (!tp1) {
      setErrorText("Signal tp1 is required.");
      return;
    }
    if (!tp2) {
      setErrorText("Signal tp2 is required.");
      return;
    }
    if (!sl) {
      setErrorText("Signal sl is required.");
      return;
    }
    if (!price) {
      setErrorText("Signal price is required.");
      return;
    }

    const result = {
      advice,
      creating_time: new Date().getTime(),
      name: id,
      price: price,
      sl: sl,
      tp1: tp1,
      tp2: tp2,
      type: category,
      level,
      origin,
    };

    const key = await writeData("Signals", result);
    setSignalsData((signals) =>
      signals ? [...signals, { ...result, key }] : null
    );
    setSuccessText("Signal created succesfully.");
    resetSignal();
  };

  const resetSignal = () => {
    if (idRef.current) {
      idRef.current.value = "";
    }
    if (categoryRef.current) {
      categoryRef.current.value = "BUY";
    }
    if (adviceRef.current) {
      adviceRef.current.value = "H1";
    }
    if (levelRef.current) {
      levelRef.current.value = "BEGINNER";
    }
    if (originRef.current) {
      originRef.current.value = "FOREX";
    }
    if (tp1Ref.current) {
      tp1Ref.current.value = "";
    }
    if (tp2Ref.current) {
      tp2Ref.current.value = "";
    }
    if (slRef.current) {
      slRef.current.value = "";
    }
    if (priceRef.current) {
      priceRef.current.value = "";
    }
  };

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header">
          <strong>New Signal</strong> WSF
        </div>
        <div className="card-body card-block">
          <div className="row form-group">
            <div className="col col-md-3">
              <label className=" form-control-label">Publisher</label>
            </div>
            <div className="col-12 col-md-9">
              <p className="form-control-static">
                {personalData.first_name} {personalData.last_name}
              </p>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label for="text-input" className=" form-control-label">
                Signal ID
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                type="text"
                ref={idRef}
                id="name"
                name="text-input"
                placeholder="Text"
                className="form-control"
              />
              <small className="form-text text-muted">Example AAPUSD</small>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label for="select" className=" form-control-label">
                Signal Category
              </label>
            </div>
            <div className="col-12 col-md-9">
              <select name="select" className="form-control" ref={categoryRef}>
                <option selected value="BUY">
                  BUY
                </option>
                <option value="SELL">SELL</option>
              </select>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label for="select" className=" form-control-label">
                Signal Advice
              </label>
            </div>
            <div className="col-12 col-md-9">
              <select name="select" className="form-control" ref={adviceRef}>
                <option selected value="H1">
                  H1
                </option>
                <option value="H2">H2</option>
                <option value="H4">H4</option>
                <option value="D1">D1</option>
                <option value="W1">W1</option>
              </select>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label for="select" className=" form-control-label">
                Signal Level
              </label>
            </div>
            <div className="col-12 col-md-9">
              <select name="select" className="form-control" ref={levelRef}>
                <option selected value="BEGINNER">
                  BEGINNER
                </option>
                <option value="INTERMEDIATE">INTERMEDIATE</option>
                <option value="EXPERT">EXPERT</option>
              </select>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label for="select" className=" form-control-label">
                Signal Origin
              </label>
            </div>
            <div className="col-12 col-md-9">
              <select name="select" className="form-control" ref={originRef}>
                <option selected value="FOREX">
                  FOREX
                </option>
                <option value="CRYPTO">CRYPTO</option>
                <option value="STOCKS">STOCKS</option>
                <option value="INDICES">INDICES</option>
                <option value="BONDS">BONDS</option>
                <option value="FUTURES">FUTURES</option>
              </select>
            </div>
          </div>
          <div className="row form-group">
            <div className="col col-md-3">
              <label for="text-input" className=" form-control-label">
                Price
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                ref={priceRef}
                type="number"
                id="price"
                name="text-input"
                className="form-control"
              />
              <small className="form-text text-muted">Example 1470.42</small>
            </div>
          </div>
          <div className="row form-group">
            <div className="col col-md-3">
              <label for="text-input" className=" form-control-label">
                T.P.1
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                type="number"
                ref={tp1Ref}
                id="tp1"
                name="text-input"
                className="form-control"
              />
              <small className="form-text text-muted">Example 1470.42</small>
            </div>
          </div>
          <div className="row form-group">
            <div className="col col-md-3">
              <label for="text-input" className=" form-control-label">
                T.P.2
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                ref={tp2Ref}
                type="number"
                id="tp2"
                name="text-input"
                className="form-control"
              />
              <small className="form-text text-muted">Example 1470.42</small>
            </div>
          </div>
          <div className="row form-group">
            <div className="col col-md-3">
              <label for="text-input" className=" form-control-label">
                S.L.
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                ref={slRef}
                type="number"
                id="sl"
                name="text-input"
                className="form-control"
              />
              <small className="form-text text-muted">Example 1470.42</small>
            </div>
          </div>
        </div>
        {errorText && (
          <div className="alert alert-danger mx-8" role="alert">
            {errorText}
          </div>
        )}
        {successText && (
          <div className="alert alert-success mx-8" role="success">
            {successText}
          </div>
        )}
        <div className="card-footer">
          <Button
            text="Submit new signal"
            type="primary"
            callback={addSignal}
          />
          <br />
          <Button text="Reset" type="danger" callback={resetSignal} />
        </div>
      </div>
    </div>
  );
};

export default NewSignal;
