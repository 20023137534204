import React, { useState, useRef } from "react";
import Button from "../../../components/Button";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useData } from "../../../hooks/useData";
import { GenerateKey } from "../../../R.js";
import {
  uploadStorageFile,
  writeData,
} from "../../../services/FirebaseService";

const NewArticle = () => {
  const {
    data: { personalData },
  } = useData();

  const titleRef = useRef(null);
  const categoryRef = useRef(null);
  const contentRef = useRef(null);

  const mp4FileRef = useRef(null);
  const imageSrcRef = useRef(null);
  const imageFileRef = useRef(null);
  const youtubeUrlRef = useRef(null);

  const [errorText, setErrorText] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [successText, setSuccessText] = useState(null);
  const [selectedMediaFormat, setSelectedMediaFormat] = useState("NO_MEDIA");
  const [uploadingProgress, setUploadingProgress] = useState(null);

  const submitArticle = async () => {
    const title = titleRef.current.value;
    const category = categoryRef.current.value;
    const content = contentRef.current.value;
    setErrorText(null);
    setUploadingProgress(null);
    if (!title) {
      setErrorText("Title field is required.");
      return;
    }
    if (!category) {
      setErrorText("Category field is required.");
      return;
    }
    if (!content) {
      setErrorText("Content field is required.");
      return;
    }
    if (title.length > 36) {
      setErrorText("Title field should not exceed 36 characters.");
      return;
    }

    const result = {
      article_author: `${personalData.first_name} ${personalData.last_name}`,
      article_category: category,
      article_title: title,
      description: content,
      creating_time: Date.now(),
      article_source: "Our Publishers",
    };

    try {
      switch (selectedMediaFormat) {
        case "MP4_FILE":
          const videoUrl = await getMp4Link();
          if (!videoUrl) {
            throw new Error("Cannot upload file.");
          }
          result.video_src = videoUrl;
          break;
        case "WEB_IMAGE":
          const imageSrc = imageSrcRef.current.value;
          if (!imageSrc) {
            throw new Error("Image source is not defined.");
          }
          if (
            (!imageSrc.endsWith(".png") &&
              !imageSrc.endsWith(".jpg") &&
              !imageSrc.endsWith(".jpeg")) ||
            !imageSrc.startsWith("http")
          ) {
            throw new Error(
              "Image source should be a url that starts with http and ends with png, jpg or jpeg."
            );
          }
          result.image_src = imageSrc;
          break;
        case "IMAGE_FILE":
          const imageUrl = await getImageLink();
          if (!imageUrl) {
            throw new Error("Cannot upload image.");
          }
          result.image_src = imageUrl;
          break;
        case "YOUTUBE_URL":
          const youtubeUrl = youtubeUrlRef.current.value;
          if (!youtubeUrl) {
            throw new Error("Youtube url is required.");
          }
          if (!youtubeUrl.startsWith("https://www.youtube.com/")) {
            throw new Error(
              "Youtube url should start with https://www.youtube.com/."
            );
          }
          result.youtube_src = youtubeUrl;
          break;
        default:
          break;
      }

      setUploadingProgress(null);
      setLoading(true);
      await writeData("Articles", result);
      setLoading(false);
      setSuccessText("Article was created succesfully creacted.");
      resetArticle();
    } catch (error) {
      console.error(error);
      setErrorText(error.message);
      setUploadingProgress(null);
      setSuccessText(null);
      setLoading(false);
      return;
    }
  };

  const getMp4Link = () => {
    if (!mp4FileRef.current.files || !mp4FileRef.current.files.length) {
      throw new Error("MP4 File not found.");
    }
    const file = mp4FileRef.current.files[0];
    if (file.type !== "video/mp4") {
      throw new Error("File's format must be MP4");
    }
    const key = GenerateKey();

    return new Promise((resolve, reject) => {
      uploadStorageFile(
        "Videos",
        `${key}.mp4`,
        file,
        (percentage) => setUploadingProgress(percentage),
        (error) => reject(error),
        (url) => resolve(url)
      );
    });
  };

  const getImageLink = () => {
    if (!imageFileRef.current.files || !imageFileRef.current.files.length) {
      throw new Error("Image File not found.");
    }
    const file = imageFileRef.current.files[0];
    if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
      throw new Error("File's format must be png, jpg or jpeg.");
    }
    const key = GenerateKey();
    return new Promise((resolve, reject) => {
      uploadStorageFile(
        "Articles",
        `${key}.${file.type}`,
        file,
        (percentage) => setUploadingProgress(percentage),
        (error) => reject(error),
        (url) => resolve(url)
      );
    });
  };

  const resetArticle = () => {
    if (titleRef.current) {
      titleRef.current.value = "";
    }
    if (categoryRef.current) {
      categoryRef.current.value = "Category.ArticleNewsFeed";
    }
    if (contentRef.current) {
      contentRef.current.innerText = "text";
    }
    if (mp4FileRef.current) {
      mp4FileRef.current.value = "";
    }
    if (imageSrcRef.current) {
      imageSrcRef.current.value = "";
    }
    if (imageFileRef.current) {
      imageFileRef.current.value = "";
    }
    if (youtubeUrlRef.current) {
      youtubeUrlRef.current.value = "";
    }
    setSelectedMediaFormat("NO_MEDIA");
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header">
          <strong>New Article</strong> WSF
        </div>
        <div className="card-body card-block">
          <div className="row form-group">
            <div className="col col-md-3">
              <label className=" form-control-label">Publisher</label>
            </div>
            <div className="col-12 col-md-9">
              <p className="form-control-static">
                {personalData.first_name} {personalData.last_name}
              </p>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label htmlFor="text-input" className=" form-control-label">
                Article Title
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                type="text"
                id="title"
                name="text-input"
                placeholder="Text"
                className="form-control"
                ref={titleRef}
              />
              <small className="form-text text-muted">
                No more than 36 Characters
              </small>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label htmlFor="select" className=" form-control-label">
                Article Category
              </label>
            </div>
            <div className="col-12 col-md-9">
              <select
                name="select"
                id="category"
                className="form-control"
                ref={categoryRef}
              >
                <option selected value="Category.ArticleNewsFeed">
                  Article News Feed
                </option>
                <option value="Category.ArticleCoach">Intuitive coach</option>
                <option value="Category.ArticleLearn">Learn</option>
                <option value="Category.PartnerSponsor">Partner Sponsor</option>
              </select>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label htmlFor="textarea-input" className=" form-control-label">
                Article Content
              </label>
            </div>
            <div className="col-12 col-md-9">
              <textarea
                name="textarea-input"
                id="textarea-input"
                rows="9"
                placeholder="Content..."
                className="form-control"
                ref={contentRef}
              ></textarea>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label htmlFor="select" className=" form-control-label">
                Media Format
              </label>
            </div>
            <div className="col-12 col-md-9">
              <select
                name="select"
                id="category"
                className="form-control"
                value={selectedMediaFormat}
                onChange={(e) => setSelectedMediaFormat(e.target.value)}
              >
                <option selected value="NO_MEDIA">
                  No media attached
                </option>
                <option value="MP4_FILE">MP4 Video file</option>
                <option value="IMAGE_FILE">Local File Image</option>
                <option value="WEB_IMAGE">Web Image Url</option>
                <option value="YOUTUBE_URL">Youtube Video Url</option>
              </select>
            </div>
          </div>

          {selectedMediaFormat === "MP4_FILE" && (
            <div className="row form-group">
              <div className="col col-md-3">
                <label htmlFor="video-input" className=" form-control-label">
                  MP4 file
                </label>
              </div>
              <div className="col-12 col-md-9">
                <input
                  type="file"
                  ref={mp4FileRef}
                  id="video-input"
                  name="file-input"
                  className="form-control-file"
                />
                <small
                  className="form-text text-muted"
                  id="video-progress"
                ></small>
              </div>
            </div>
          )}

          {selectedMediaFormat === "WEB_IMAGE" && (
            <div className="row form-group">
              <div className="col col-md-3">
                <label htmlFor="text-input" className=" form-control-label">
                  Web Image Link
                </label>
              </div>
              <div className="col-12 col-md-9">
                <input
                  type="text"
                  ref={imageSrcRef}
                  id="image_url"
                  name="text-input"
                  placeholder="https://yourinternetsite.com/image.png"
                  className="form-control"
                />
                <small className="form-text text-muted">
                  Should ends with .png .jpg or .jpeg
                </small>
              </div>
            </div>
          )}

          {selectedMediaFormat === "IMAGE_FILE" && (
            <div className="row form-group">
              <div className="col col-md-3">
                <label htmlFor="file-input" className=" form-control-label">
                  Image FILE
                </label>
              </div>
              <div className="col-12 col-md-9">
                <input
                  ref={imageFileRef}
                  type="file"
                  id="image-input"
                  name="file-input"
                  className="form-control-file"
                />
                <small className="form-text text-muted">
                  Allowed formats are .png .jpg or .jpeg
                </small>
                <small
                  className="form-text text-muted"
                  id="upload_progress"
                ></small>
              </div>
            </div>
          )}

          {selectedMediaFormat === "YOUTUBE_URL" && (
            <div className="row form-group">
              <div className="col col-md-3">
                <label htmlFor="text-input" className=" form-control-label">
                  Youtube Video Link
                </label>
              </div>
              <div className="col-12 col-md-9">
                <input
                  type="text"
                  ref={youtubeUrlRef}
                  id="image_url"
                  name="text-input"
                  placeholder="https://www.youtube.com/watch?v=vFnGb10tZ5M"
                  className="form-control"
                />
                <small className="form-text text-muted">
                  Should start with https://www.youtube.com/
                </small>
              </div>
            </div>
          )}
        </div>
        {errorText && (
          <div className="alert alert-danger mx-8" role="alert">
            {errorText}
          </div>
        )}
        {uploadingProgress != null && (
          <div className="alert alert-danger mx-8" role="info">
            Uploading file: {uploadingProgress}%<br />
            Please keep the current page open.
          </div>
        )}
        {successText && (
          <div className="alert alert-success mx-8" role="info">
            {successText}
          </div>
        )}
        <div className="card-footer">
          <Button
            text="Submit new article"
            type="primary"
            callback={submitArticle}
          />
          <br />
          <Button text="Reset" type="warning" callback={resetArticle} />
        </div>
      </div>
    </div>
  );
};

export default NewArticle;
