export const USER_ROLE_MEMBER = "Member";
export const USER_ROLE_ADMIN = "Administrator";

export const FormatDate = (local) => {
  return local.toJSON().slice(0, 10);
};

export const GenerateKey = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const firebaseConfig = {
  apiKey: "AIzaSyB15ydDg8TQUh6I69xRNkXiqQ28dPxKgwM",
  authDomain: "wallstreetforex-e9e8a.firebaseapp.com",
  databaseURL: "https://wallstreetforex-e9e8a-default-rtdb.firebaseio.com",
  projectId: "wallstreetforex-e9e8a",
  storageBucket: "wallstreetforex-e9e8a.appspot.com",
  messagingSenderId: "956263396116",
  appId: "1:956263396116:web:b1096c5415c863db608801",
  measurementId: "G-BZX2ETXE8C",
};