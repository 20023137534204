import React, { useEffect, useState, useCallback } from "react";
import logoImage from "../../assets/images/logo-white.png";
import Actions from "./actions/Actions";
import "./HomePage.css";
import { DataContextProvider } from "../../hooks/useData";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getSingleUser } from "../../services/FirebaseService";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { USER_ROLE_ADMIN } from "../../R.js";
import LoadingSpinner from "../../components/LoadingSpinner";
import Articles from "./articles/Articles";
import NewArticle from "./articles/NewArticle";
import Signals from "./signals/Signals";
import NewSignal from "./signals/NewSignal";
import Users from "./users/Users";
import NewUser from "./users/NewUser";
import Contacts from "./Contact/Contacts";
import NewNotification from "./Notification/NewNotification";
import ApplicationDesign from "./application-design/ApplicationDesign";

const HomePage = () => {
  const [connectedUser, setConnectedUser] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const { page } = useParams();
  const ComponentToDisplay = useCallback(() => {
    const lowerPage = page.toLowerCase();
    switch (lowerPage) {
      case "home":
        return <Actions />;
      case "articles":
        return <Articles />;
      case "new-article":
        return <NewArticle />;
      case "new-signal":
        return <NewSignal />;
      case "signals":
        return <Signals />;
      case "users":
        return <Users />;
      case "new-user":
        return <NewUser />;
      case "contact":
        return <Contacts />;
      case "notification":
        return <NewNotification />;
      case "design":
        return <ApplicationDesign />;
      default:
        return <Actions />;
    }
  }, [page]);
  const navigate = useNavigate();
  useEffect(() => {
    const loadUsers = async (uid) => {
      const user = await getSingleUser(uid);
      if (!user) {
        navigate("/login?not-connected");
        return;
      }
      if (user.user_role !== USER_ROLE_ADMIN) {
        navigate("/login?not-allowed");
        return;
      }
      setConnectedUser(user);
      setLoaded(true);
    };
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        loadUsers(user.uid);
      } else {
        navigate("/login?not-connected");
        return;
      }
    });
  }, []);

  return (
    <div className="page-wrapper">
      <header className="header-mobile d-block d-lg-none">
        <div className="header-mobile__bar">
          <div className="container-fluid">
            <div className="header-mobile-inner">
              <a className="logo" href="index.html">
                <img src={logoImage} alt="WSF" className="h-20" />
              </a>
              {isLoaded && connectedUser ? (
                <div className="flex items-center justify-between w-[150px]">
                  <p className="">
                    {connectedUser.first_name} {connectedUser.last_name}
                  </p>
                  {connectedUser.user_profile_picture && (
                    <img
                      className="h-10 rounded"
                      alt="Profile"
                      src={connectedUser.user_profile_picture}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </header>

      <aside className="menu-sidebar d-none d-lg-block">
        <div className="logo">
          <img src={logoImage} alt="WSF" className="logo" />
        </div>
        <div className="menu-sidebar__content js-scrollbar1">
          <nav className="navbar-sidebar">
            <ul className="list-unstyled navbar__list">
              <li>
                <Link to="/Home">
                  <i className="fas fa-tachometer-alt"></i>Main Page
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>

      <div className="page-container">
        <header className="header-desktop d-none d-lg-block">
          <div className="">
            <div className="container-fluid">
              <div className="header-wrap">
                <div className="header-button ml-auto w-[200px]">
                  <div className="account-wrap">
                    <div className="account-item clearfix js-item-menu">
                      <div className="content">
                        {isLoaded && connectedUser ? (
                          <div className="flex items-center justify-between w-[150px]">
                            <p className="">
                              {connectedUser.first_name}{" "}
                              {connectedUser.last_name}
                            </p>
                            {connectedUser.user_profile_picture && (
                              <img
                                className="h-10 rounded"
                                alt="Profile"
                                src={connectedUser.user_profile_picture}
                              />
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="main-content">
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                {isLoaded && connectedUser ? (
                  <DataContextProvider connectedUser={connectedUser}>
                    {<ComponentToDisplay />}
                  </DataContextProvider>
                ) : (
                  <LoadingSpinner />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
