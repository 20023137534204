import React, { useState, useRef } from "react";
import Button from "../../../components/Button";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useData } from "../../../hooks/useData";
import {
  createNewUser,
  uploadStorageFile,
  writeData,
} from "../../../services/FirebaseService";

const NewUser = () => {
  const emailRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordRepeatRef = useRef(null);
  const roleRef = useRef(null);
  const expirationRef = useRef(null);

  const [errorText, setErrorText] = useState(null);
  const [successText, setSuccessText] = useState(null);
  const [isLoading, setLoading] = useState(null);

  const createUser = async () => {
    setSuccessText(null);
    setErrorText(null);
    if (!emailRef.current.value) {
      setErrorText("Email Address is required.");
      return;
    }
    if (!firstNameRef.current.value) {
      setErrorText("First Name is required.");
      return;
    }
    if (!lastNameRef.current.value) {
      setErrorText("Last Name is required.");
      return;
    }
    if (!passwordRef.current.value) {
      setErrorText("Password is required.");
      return;
    }
    if (!passwordRepeatRef.current.value) {
      setErrorText("Password Repeat is required.");
      return;
    }
    if (!roleRef.current.value) {
      setErrorText("Role is required.");
      return;
    }
    if (!expirationRef.current.value) {
      setErrorText("Expiration is required.");
      return;
    }
    const emailAddress = emailRef.current.value;
    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;
    const password = passwordRef.current.value;
    const passwordRepeat = passwordRepeatRef.current.value;
    const role = roleRef.current.value;
    const expiration = expirationRef.current.value;

    if (password.length < 6) {
      setErrorText("Password is not strong.");
      return;
    }
    if (password !== passwordRepeat) {
      setErrorText("Two passwords are not the same.");
      return;
    }

    const result = {
      auto_renewal: true,
      creating_time: new Date().getTime(),
      email_address: emailAddress,
      expiring_time: Date.parse(expiration),
      first_name: firstName,
      last_name: lastName,
      user_bio: "",
      user_role: role,
      account_active: true,
    };

    try {
      setLoading(true);
      await createNewUser(result, password);
      setSuccessText("User created succesfully.");
    } catch (error) {
      setErrorText(error.message);
    }
    setLoading(false);
  };

  const resetData = () => {};
  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header">
          <strong>New Member</strong> WSF
        </div>

        <div className="card-body card-block">
          <div className="row form-group">
            <div className="col col-md-3">
              <label for="select" className=" form-control-label">
                Member email address
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                type="email"
                ref={emailRef}
                placeholder="Email Address"
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col col-md-3">
              <label for="text-input" className=" form-control-label">
                Member First Name
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                type="text"
                ref={firstNameRef}
                placeholder="First name"
                className="form-control"
              />
              <small className="form-text text-muted">
                No more than 36 Characters.
              </small>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label for="text-input" className="form-control-label">
                Member Last Name
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                type="text"
                ref={lastNameRef}
                placeholder="Last name"
                className="form-control"
              />
              <small className="form-text text-muted">
                No more than 36 Characters.
              </small>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label for="text-input" className=" form-control-label">
                Member Password
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                type="password"
                ref={passwordRef}
                className="form-control"
              />
              <small className="form-text text-muted">
                Password should be strong.
              </small>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label for="text-input" className=" form-control-label">
                Repeat Password
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                type="password"
                ref={passwordRepeatRef}
                className="form-control"
              />
              <small className="form-text text-muted">
                Password should be strong.
              </small>
            </div>
          </div>

          <div className="row form-group">
            <div className="col col-md-3">
              <label for="select" className=" form-control-label">
                Role
              </label>
            </div>
            <div className="col-12 col-md-9">
              <select ref={roleRef} className="form-control">
                <option selected value="Member">
                  Member
                </option>
                <option value="Coach">Coach</option>
                <option value="Administrator">Admin</option>
              </select>
            </div>
          </div>
          <div className="row form-group">
            <div className="col col-md-3">
              <label for="text-input" className=" form-control-label">
                Session Expiration date
              </label>
            </div>
            <div className="col-12 col-md-9">
              <input
                type="datetime-local"
                ref={expirationRef}
                placeholder="Text"
                className="form-control"
              />
              <small className="form-text text-muted">
                Session expiration date and time.
              </small>
            </div>
          </div>
        </div>
        {errorText && (
          <div className="alert alert-danger mx-8" role="alert">
            {errorText}
          </div>
        )}
        {successText && (
          <div className="alert alert-success mx-8" role="success">
            {successText}
          </div>
        )}
        <div className="card-footer">
          <Button text="Add User" type="primary" callback={createUser} />
          <br />
          <Button text="Reset" type="danger" callback={resetData} />
        </div>
      </div>
    </div>
  );
};

export default NewUser;
