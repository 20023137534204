import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./App.css";
import LoginPage from "./pages/login/LoginPage";
import HomePage from "./pages/home/HomePage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/:page" element={<HomePage />} />
        <Route path="/" element={<Navigate replace to="/Home" />} />
      </Routes>
    </Router>
  );
}

export default App;
