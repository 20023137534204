import React, { useContext, useState, useEffect } from "react";
import {
  getArticles,
  getSignals,
  getUsers,
  getContacts,
} from "../services/FirebaseService";

const dataContext = React.createContext(null);

export const DataContextProvider = ({ children, connectedUser }) => {
  const [personalData, setPersonalData] = useState(connectedUser);
  const [signalsData, setSignalsData] = useState(null);
  const [articlesData, setArticlesData] = useState(null);
  const [usersData, setUsersData] = useState(null);
  const [contactData, setContactData] = useState(null);

  const value = {
    data: {
      personalData,
      signalsData,
      articlesData,
      usersData,
      contactData,
    },
    setters: {
      setPersonalData,
      setSignalsData,
      setArticlesData,
      setUsersData,
      setContactData,
    },
  };
  return <dataContext.Provider value={value}>{children}</dataContext.Provider>;
};

export const useData = () => {
  const data = useContext(dataContext);
  return data;
};

export const useArticles = (forceUpdate = false) => {
  const {
    setters: { setArticlesData },
    data: { articlesData },
  } = useData();
  if (!articlesData || forceUpdate) {
    getArticles().then((articles) => {
      articles = Object.keys(articles).map((key) => ({
        ...articles[key],
        key,
      }));
      setArticlesData(articles);
    });
    return [null, setArticlesData];
  }
  return [articlesData, setArticlesData];
};

export const useSignals = (forceUpdate = false) => {
  const {
    setters: { setSignalsData },
    data: { signalsData },
  } = useData();
  if (!signalsData || forceUpdate) {
    getSignals().then((articles) => {
      articles = Object.keys(articles).map((key) => ({
        ...articles[key],
        key,
      }));
      setSignalsData(articles);
    });
    return [null, setSignalsData];
  }
  return [signalsData, setSignalsData];
};

export const useUsers = (forceUpdate = false) => {
  const {
    setters: { setUsersData },
    data: { usersData },
  } = useData();
  if (!usersData || forceUpdate) {
    getUsers().then((users) => {
      users = Object.keys(users).map((key) => ({
        ...users[key],
        key,
      }));
      setUsersData(users);
    });
    return [null, setUsersData];
  }
  return [usersData, setUsersData];
};

export const useContacts = (forceUpdate = false) => {
  const {
    setters: { setContactData },
    data: { contactData },
  } = useData();
  if (!contactData || forceUpdate) {
    getContacts().then((contacts) => {
      contacts = Object.keys(contacts).map((key) => ({
        ...contacts[key],
        key,
      }));
      setContactData(contacts);
    });
    return [null, setContactData];
  }
  return [contactData, setContactData];
};
