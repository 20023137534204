import React, { useState, useMemo } from "react";
import Button from "../../../components/Button";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useSignals } from "../../../hooks/useData";
import { removeData, updateData } from "../../../services/FirebaseService";

const SignalTr = ({
  name,
  type,
  price,
  sl,
  tp1,
  tp2,
  date,
  hit,
  be,
  setHit,
  setBE,
  removeFunction,
  isSelected,
  setSelection,
}) => {
  return (
    <tr>
      <td className="w-2">
        <label className="au-checkbox">
          <input
            type="checkbox"
            checked={isSelected}
            onChange={(e) => setSelection(e.target.checked)}
          />
          <span className="au-checkmark"></span>
        </label>
      </td>
      <td>
        <div className="table-data__info">
          <p>{name}</p>
        </div>
      </td>
      <td>
        <div className="table-data__info">
          <p className={type === "BUY" ? "text-green-600" : "text-red-600"}>
            {type}
          </p>
        </div>
      </td>
      <td>
        <div className="table-data__info">
          <p>{price}</p>
        </div>
      </td>
      <td>
        <div className="table-data__info">
          <p>
            {sl}{" "}
            {!!be && (
              <span>
                <br /> BE: {be}
              </span>
            )}
          </p>
        </div>
      </td>
      <td>
        <div className="table-data__info">
          <p>{tp1}</p>
        </div>
      </td>
      <td>
        <div className="table-data__info">
          <p>{tp2}</p>
        </div>
      </td>
      <td>
        <div className="table-data__info">
          <p>{date}</p>
        </div>
      </td>
      <td>
        {hit ? (
          <>
            <span
              className={`badge rounded-pill bg-${
                hit === "SL" ? "danger" : "success"
              } w-full mb-2 py-2 text-white`}
            >
              Price touched {hit}
            </span>
            {hit === "TP1" && (
              <>
                <Button
                  text="TP2 Hit"
                  type="primary"
                  callback={() => setHit("TP2")}
                  isSmall={true}
                />
                <Button
                  text="SL Hit"
                  type="warning"
                  callback={() => setHit("SL")}
                  isSmall={true}
                />
                {!be && (
                  <Button
                    text="Set SL to BE"
                    type="info"
                    callback={setBE}
                    isSmall={true}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Button
              text="TP1 Hit"
              type="primary"
              callback={() => setHit("TP1")}
              isSmall={true}
            />
            <Button
              text="TP2 Hit"
              type="primary"
              callback={() => setHit("TP2")}
              isSmall={true}
            />
            <Button
              text="SL Hit"
              type="warning"
              callback={() => setHit("SL")}
              isSmall={true}
            />
            {!be && (
              <Button
                text="Set SL to BE"
                type="info"
                callback={setBE}
                isSmall={true}
              />
            )}
          </>
        )}

        <Button
          text="Delete"
          type="danger"
          callback={removeFunction}
          isSmall={true}
        />
      </td>
    </tr>
  );
};

const Signals = () => {
  const [signals, setSignalsData] = useSignals();

  const [selected, setSelected] = useState([]);

  const filteredSignals = useMemo(
    () =>
      signals &&
      signals.length &&
      signals.sort((s1, s2) => s2.creating_time - s1.creating_time),
    [signals]
  );

  const setHit = async (key, hit) => {
    const signal = signals.find((s) => s.key === key);
    if (!signal) {
      return;
    }
    signal.hit = hit;
    await updateData(`Signals/${key}`, signal);
    setSignalsData((signals) =>
      signals.map((s) => (s.key === key ? signal : s))
    );
  };

  const setBE = async (key) => {
    const signal = signals.find((s) => s.key === key);
    if (!signal) {
      return;
    }
    signal.be = signal.price;
    await updateData(`Signals/${key}`, signal);
    setSignalsData((signals) =>
      signals.map((s) => (s.key === key ? signal : s))
    );
  };

  const deleteSignals = async (key) => {
    await removeData(`Signals/${key}`);
    setSignalsData((liste) => liste.filter((s) => s.key !== key));
    setSelected([]);
  };

  const setSelection = (key, isSelected) => {
    if (isSelected) {
      setSelected((s) => [...s, key]);
    } else {
      setSelected((s) => s.filter((k) => k !== key));
    }
  };

  const deleteSelection = () => {
    console.log(selected);
    if (!selected || !selected.length) {
      return;
    }
    selected.forEach((key) => deleteSignals(key));
  };

  return filteredSignals ? (
    <div className="col-lg-12">
      <div className="user-data m-b-30">
        <div className="flex content-between justify-between">
          <h3 className="title-3 m-b-30">
            <i className="zmdi zmdi-account-calendar"></i>Signals list
          </h3>
          {selected && !!selected.length && (
            <div className="mr-4">
              <Button
                text="Delete Selected"
                type="danger"
                callback={deleteSelection}
                isSmall={false}
              />
            </div>
          )}
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <td>Select</td>
                <td>Name</td>
                <td>Type</td>
                <td>Price</td>
                <td>SL</td>
                <td>TP1</td>
                <td>TP2</td>
                <td>Date</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {signals.map(
                ({
                  key,
                  name,
                  type,
                  price,
                  sl,
                  tp1,
                  tp2,
                  creating_time,
                  be,
                  hit,
                }) => (
                  <SignalTr
                    name={name}
                    type={type}
                    price={price}
                    sl={sl}
                    tp1={tp1}
                    tp2={tp2}
                    hit={hit}
                    be={be}
                    setHit={(hit) => setHit(key, hit)}
                    setBE={() => setBE(key)}
                    removeFunction={() => deleteSignals(key)}
                    isSelected={selected.includes(key)}
                    setSelection={(checked) => setSelection(key, checked)}
                    date={new Date(creating_time).toLocaleDateString()}
                  />
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default Signals;
