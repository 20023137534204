import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { getDatabase, ref, child, get, set, push } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { firebaseConfig } from "../R";

export const getSingleUser = async (uid) => {
  const db = getDatabase();
  const snapshot = await get(ref(db, `Users/${uid}`));
  if (!snapshot.exists()) {
    return null;
  }
  return snapshot.val();
};

export const getUsers = async () => {
  return await readData("Users");
};

export const getArticles = async () => {
  return await readData("Articles");
};

export const getSignals = async () => {
  return await readData("Signals");
};

export const getContacts = async () => {
  return await readData("Contact");
};

export const uploadStorageFile = (
  category,
  name,
  content,
  onUpdate = (p) => {},
  onError = (e) => {},
  onComplete = (url) => {}
) => {
  const storage = getStorage();
  const savingRef = storageRef(storage, `${category}/${name}`);
  const task = uploadBytesResumable(savingRef, content);
  task.on(
    "state_changed",
    (snapshot) => {
      const percentage = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      onUpdate(percentage);
    },
    onError,
    async () => {
      const downloadUrl = await getDownloadURL(task.snapshot.ref);
      onComplete(downloadUrl);
    }
  );
};

export const readData = async (path) => {
  const db = getDatabase();
  const snapshot = await get(ref(db, path));
  if (!snapshot.exists()) {
    return null;
  }
  return snapshot.val();
};

export const updateData = async (path, value) => {
  const db = getDatabase();
  await set(ref(db, path), value);
};

export const writeData = async (path, value) => {
  const db = getDatabase();
  const key = push(child(ref(db), path)).key;
  await updateData(`${path}/${key}`, value);
  return key;
};

export const removeData = async (path) => {
  updateData(path, null);
};

export const createNewUser = async (data, password) => {
  const secondaryApp = initializeApp(firebaseConfig, "Secondary");
  const auth = getAuth(secondaryApp);
  const createdUser = await createUserWithEmailAndPassword(
    auth,
    data.email_address,
    password
  );
  const user = createdUser.user;
  await updateData(`Users/${user.uid}`, data);
  await signOut(auth);
};