import React, { useEffect, useState, useMemo } from "react";
import Button from "../../../components/Button";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useArticles } from "../../../hooks/useData";
import { FormatDate } from "../../../R.js";
import { removeData } from "../../../services/FirebaseService";

const ArticleTr = ({
  article_source,
  article_author,
  article_title,
  article_category,
  date,
  description,
  removeFunction,
}) => {
  return (
    <tr>
      <td>
        <div className="table-data__info">
          <h6>{article_source}</h6>
          <span>{article_author}</span>
        </div>
      </td>
      <td>
        <div className="table-data__info">
          <h6>{article_title}</h6>
        </div>
      </td>
      <td>
        <div className="table-data__info">
          <h6>{article_category}</h6>
        </div>
      </td>
      <td>
        <div className="table-data__info">
          <h6>{date}</h6>
        </div>
      </td>
      <td>
        <div className="table-data__info">
          <h6>{description}</h6>
        </div>
      </td>
      <td>
        <Button text="Remove Article" type="danger" callback={removeFunction} />
      </td>
    </tr>
  );
};

const Articles = () => {
  const [articles, setArticlesData] = useArticles();
  const defaultStartingDate = useMemo(
    () =>
      new Date(
        new Date().getTime() - 1000 * 60 * 60 * 24 * 7 // 7 days from now
      ),
    []
  );
  const [filter, setFilter] = useState({
    source: "Our Publishers",
    startingFrom: FormatDate(defaultStartingDate),
  });
  const filteredArticles = useMemo(() => {
    const startingDateMillis = filter.startingFrom
      ? new Date(filter.startingFrom).getTime()
      : 0;
    return (
      articles &&
      articles
        .filter(
          (article) =>
            (!filter.source ||
              (article.article_source &&
                article.article_source === filter.source)) &&
            startingDateMillis <= article.creating_time
        )
        .sort((a1, a2) => a2.creating_time - a1.creating_time)
    );
  }, [articles, filter]);

  const removeArticle = async (key) => {
    await removeData(`Articles/${key}`);
    setArticlesData((liste) => liste.filter((a) => a.key !== key));
  };

  return filteredArticles ? (
    <div className="col-lg-12">
      <div className="user-data m-b-30">
        <div className="flex justify-between">
          <div>
            <h3 className="title-3 m-b-30">
              <i className="zmdi zmdi-account-calendar"></i>Articles list
            </h3>
          </div>
          <div className="mr-2 flex flex-col lg:flex-row">
            <div className="mr-2">
              <label>Source: </label>
              <select
                value={filter.source}
                onChange={(event) =>
                  setFilter({ ...filter, source: event.target.value })
                }
                className="block w-full h-50 p-2 mb-6 text-sm text-gray-400 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
              >
                <option value="Our Publishers">Our Publishers</option>
                <option value="Seeking-Alpha">Seeking-Alpha</option>
              </select>
            </div>
            <div>
              <label>Starting from: </label>
              <input
                type="date"
                value={filter.startingFrom}
                onChange={(event) =>
                  setFilter({ ...filter, startingFrom: event.target.value })
                }
                className="className=block w-full h-50 p-2 mb-6 text-sm text-gray-400 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <td>Source</td>
                <td>Title</td>
                <td>Category</td>
                <td>Date</td>
                <td>Content</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {filteredArticles.length
                ? filteredArticles.map(
                    ({
                      key,
                      article_author,
                      article_source,
                      article_title,
                      creating_time,
                      description,
                      article_category,
                    }) => (
                      <ArticleTr
                        key={key}
                        article_source={article_source}
                        article_author={article_author}
                        article_title={article_title}
                        article_category={article_category}
                        date={new Date(creating_time).toLocaleDateString()}
                        description={description}
                        removeFunction={() => removeArticle(key)}
                      />
                    )
                  )
                : ""}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default Articles;
