import React, { useEffect, useState, useMemo } from "react";
import Button from "../../../components/Button";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useContacts, useUsers } from "../../../hooks/useData";
import { updateData } from "../../../services/FirebaseService";

const ContactTr = ({
  user_name,
  user_email,
  date,
  title,
  message,
  isSolved,
  solveRequest,
}) => {
  return (
    <tr>
      <td>
        <div class="table-data__info">
          <h6>{user_name}</h6>
          <span>
            <a href={`mailto:{user_email}`}>{user_email}</a>
          </span>
        </div>
      </td>
      <td>
        <div class="table-data__info">
          <h6>{date}</h6>
        </div>
      </td>
      <td>
        <div class="table-data__info">
          <h6>{title}</h6>
        </div>
      </td>
      <td>
        <div class="table-data__info">
          <h6>{message}</h6>
        </div>
      </td>
      <td>
        {isSolved ? (
          "Solved"
        ) : (
          <Button
            text="Mark as solved"
            type="danger"
            callback={solveRequest}
            isSmall={true}
          />
        )}
      </td>
    </tr>
  );
};

const Contacts = () => {
  const [contacts, setContactData] = useContacts();
  const [users] = useUsers();

  const getUser = (uid) => {
    if (!uid) {
      return null;
    }
    return users.find((u) => u.key === uid);
  };

  const filteredContacts = useMemo(
    () =>
      contacts &&
      users &&
      contacts
        .sort((c1, c2) => c2.creating_time - c1.creating_time)
        .map((c) => ({ ...c, user: getUser(c.publisher_id) })),
    [contacts, users]
  );

  const solve = async (key) => {
    await updateData(`Contact/${key}/status`, "solved");
    setContactData((contacts) =>
      contacts.map((c) => (c.key === key ? { ...c, status: "solved" } : c))
    );
  };

  return users && filteredContacts ? (
    <div className="col-lg-12">
      <div className="user-data m-b-30">
        <h3 className="title-3 m-b-30">
          <i className="zmdi zmdi-account-calendar"></i>users data
        </h3>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <td>User Name</td>
                <td>Date</td>
                <td>Title</td>
                <td>Message</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {filteredContacts.map(
                ({ key, status, title, creating_time, user, body }) => (
                  <ContactTr
                    user_name={
                      user
                        ? `${user.first_name} ${user.last_name}`
                        : "Deleted User"
                    }
                    user_email={user ? user.email_address : key}
                    date={new Date(creating_time).toLocaleDateString()}
                    title={title}
                    message={body}
                    isSolved={status === "solved"}
                    solveRequest={() => solve(key)}
                  />
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default Contacts;
