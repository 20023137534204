const Button = ({ text, type, callback, isSmall = false }) => {
  return (
    <button
      type="button"
      onClick={callback}
      className={`btn btn-outline-${type} btn-${
        isSmall ? "sm" : "lg"
      } btn-block`}
    >
      {text}
    </button>
  );
};

export default Button;
