import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
const Actions = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full">
      <div className="mb-4 mx-4">
        <Button
          text="Add a new article"
          type="secondary"
          callback={() => navigate("/new-article")}
        />
      </div>
      <div className="mb-4 mx-4">
        <Button
          text="View articles"
          type="info"
          callback={() => navigate("/articles")}
        />
      </div>
      <div className="mb-4 mx-4">
        <Button
          text="Add a new signal"
          type="secondary"
          callback={() => navigate("/new-signal")}
        />
      </div>
      <div className="mb-4 mx-4">
        <Button
          text="View signals"
          type="info"
          callback={() => navigate("/signals")}
        />
      </div>
      <div className="mb-4 mx-4">
        <Button
          text="Add a new user"
          type="secondary"
          callback={() => navigate("/new-user")}
        />
      </div>
      <div className="mb-4 mx-4">
        <Button
          text="View users"
          type="info"
          callback={() => navigate("/users")}
        />
      </div>
      <div className="mb-4 mx-4">
        <Button
          text="View contact requests"
          type="info"
          callback={() => navigate("/contact")}
        />
      </div>
      <div className="mb-4 mx-4">
        <Button
          text="Send notification"
          type="danger"
          callback={() => navigate("/notification")}
        />
      </div>
      <div className="mb-4 mx-4">
        <Button
          text="Application design"
          type="info"
          callback={() => navigate("/design")}
        />
      </div>
    </div>
  );
};

export default Actions;
